import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {ConsumerAccountService} from './consumer.account.service';
import {user as authUser} from '@angular/fire/auth';
import {take} from 'rxjs/operators';


@Injectable()
export class ConsumerAnonAuthGuard  {
    constructor(
        private acctSvc: ConsumerAccountService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve) => {

            authUser(this.acctSvc.auth)
                .pipe(take(1))
                .subscribe(
                async (user: any) => {
                    if (!user) {
                        await this.acctSvc.signInAnon();
                    }
                    resolve(true);
                },
                async (err: any) => {
                    console.warn(err);
                    resolve(false);
                }
            );

        });
    }
}
