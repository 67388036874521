"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxekQuote = exports.AxekCart = exports.AxekOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class AxekOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'orders', idLen || 26);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AxekOrder);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
    add(p, i) {
        if (p === 'items') {
            if (!this.items.find(i => i.sku === i.sku)) {
                i.quantity = i.quantity || 1;
                i.unit_cost = i.cost;
                i.total_weight = (i.weight || 0 * i.quantity);
                i.calc();
                this.items.push(i);
                if (this._docRef) {
                    i.save(this);
                    this.save();
                }
            }
        }
        else {
            super.add(p, i);
        }
    }
}
exports.AxekOrder = AxekOrder;
class AxekCart extends AxekOrder {
    constructor(data, olMap) {
        super(data, olMap);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AxekCart);
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new AxekOrder(await super.clean(new AxekOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.AxekCart = AxekCart;
class AxekQuote extends AxekOrder {
    constructor(data, olMap) {
        super(data, olMap, 'quotes', 26);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AxekQuote);
    }
    async copy(parent) {
        return new AxekQuote(await super.clean(new AxekQuote(this), parent, ['items', 'shipments'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new AxekOrder(await super.clean(new AxekOrder(this), parent, ['items', 'shipments'], null, true));
    }
}
exports.AxekQuote = AxekQuote;
index_1.olm.orders = (ref, map) => {
    return new AxekOrder(ref, map);
};
index_1.olm.quotes = (ref, map) => {
    return new AxekQuote(ref, map);
};
