"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignIn = exports.Base = void 0;
const Common_1 = require("./Common");
const index_1 = require("../index");
class Base {
    constructor(type, idLen) {
        this.id = '';
        this.date = null;
        this.last_date = null;
        this.active = null;
        // This prevents these properties from printing in console.logs and hides the property names from Object.keys()
        (this._d || []).concat(['_p', '_s', '_m', '_l', '_d', '_type', '_exists', '_new', '_docRef']).forEach(p => {
            Object.defineProperty(this, p, { writable: true, enumerable: false, configurable: true });
        });
        this._type = type;
        this._l = idLen;
    }
    /**
     * Every class in the model needs a toString method that provides
     * a simple, string representation of the object. This is used in
     * UI and can also be helpful when logging.
     */
    toString() {
        return `${this['name'] || this['label'] || this['title']}`;
    }
    /**
     * Each class has a unique id of a particular length.  No need to use
     * guids for absolutely everything, especially when the number of records
     * is small (e.g. a users' list of devices or an order's items). The item
     * doesn't need to be universally unique, only unique within its collection
     * in the data tree.
     */
    setID() {
        this.id = (0, Common_1.getSID)(this['_l']);
    }
    /**
     * Every object is inserted into Firebase as a plain, JSON object.
     * Therefore, every class must create a JSON representation for saving.
     * values cannot be undefined or fully-instantiated model objects.
     */
    toJSON() {
        // Save a prototype of the obj. For serialization later.
        let r = {
            id: this.id
        };
        Object.keys(this).forEach((p) => {
            if (p) {
                if (this._p[p] instanceof index_1.ChildArray) {
                    delete r[p];
                }
                else if (this._p[p] instanceof Base) {
                    r[p] = (0, index_1.toMinJSON)(this, p);
                }
                else if (!p.match(/^_/) && this[p] !== undefined) {
                    r[p] = (0, index_1.toMinJSON)(this, p);
                }
                else if (p.match(/^_/) || this[p] === undefined) {
                    delete r[p];
                }
            }
        });
        return r;
    }
    toMinJSON(ignoreDocRef, publish) {
        var _a;
        let r = {
            id: this.id,
            _type: this._type
        };
        if (this._docRef) {
            if (!ignoreDocRef) {
                if ((publish === null || publish === void 0 ? void 0 : publish.fs) && (publish === null || publish === void 0 ? void 0 : publish.source) && (publish === null || publish === void 0 ? void 0 : publish.dest)) {
                    r._docRef = publish.fs.doc(this._docRef.path.replace(publish.source, publish.dest));
                }
                else {
                    r._docRef = this._docRef;
                }
            }
        }
        if ((_a = this._m) === null || _a === void 0 ? void 0 : _a.length) {
            this._m.forEach(p => {
                r[p] = (0, index_1.toMinJSON)(this, p, ignoreDocRef, publish);
            });
        }
        return r;
    }
    toFullJSON(publish) {
        let r = {};
        Object.keys(this).forEach((p) => {
            if (p) {
                if (!p.match(/^_/) && this[p] !== undefined) {
                    r[p] = (0, index_1.toFullJSON)(this, p, publish);
                }
                else if (p.match(/^_/) || this[p] === undefined) {
                    delete r[p];
                }
            }
        });
        r._type = this._type;
        return r;
    }
    async toSearchJSON(opts) {
        var _a, _b, _c;
        let r = {
            id: this.id,
            _type: this._type,
            objectID: (_a = this._docRef) === null || _a === void 0 ? void 0 : _a.path,
            last_date: this.last_date || this.date,
            date: this.date
        };
        if (this._docRef) {
            let clientRef = this._docRef;
            r._client = this.id;
            while (((_b = clientRef.parent) === null || _b === void 0 ? void 0 : _b.id) && clientRef.parent.id !== 'clients') {
                clientRef = clientRef.parent;
                r._client = clientRef.id;
            }
            await (0, index_1.loadAll)(this, opts);
        }
        if ((_c = this._s) === null || _c === void 0 ? void 0 : _c.length) {
            this._s.forEach(p => {
                r[p] = (0, index_1.toMinJSON)(this, p, true);
            });
        }
        else {
            Object.assign(r, this.toJSON());
        }
        return r;
    }
    /**
     * Array properties are common, such as message.recipients, message.cc,
     * thread.followers, etc.  The add/remove methods make it easy to add and
     * remove objects to/from those arrays.
     * @param p
     * @param value
     */
    add(p, value) {
        if (value) {
            if (!this[p]) {
                this[p] = [];
            }
            let addItem = (i) => {
                if ((i === null || i === void 0 ? void 0 : i.id) && this[p] && this[p].find) {
                    let index = this[p].findIndex(item => item.id === i.id);
                    if (index === -1) {
                        this[p].push(i);
                    }
                    else {
                        this[p][index] = i;
                    }
                }
                else if (i && typeof i !== 'object' && this[p] && this[p].find && !this[p].find(item => item === i)) {
                    this[p].push(i);
                }
            };
            if (value instanceof Array) {
                value === null || value === void 0 ? void 0 : value.forEach(i => addItem(i));
            }
            else {
                addItem(value);
            }
        }
    }
    remove(property, value) {
        if (value) {
            if (!this[property]) {
                this[property] = [];
            }
            let removeItem = (i) => {
                let done;
                while (!done) {
                    let n = this[property].findIndex(item => item === i || ((item === null || item === void 0 ? void 0 : item.id) && (item === null || item === void 0 ? void 0 : item.id) === (i === null || i === void 0 ? void 0 : i.id)));
                    if (n > -1) {
                        this[property].splice(n, 1);
                    }
                    else {
                        done = true;
                    }
                }
            };
            if (value instanceof Array) {
                value === null || value === void 0 ? void 0 : value.forEach(i => removeItem(i));
            }
            else {
                removeItem(value);
            }
        }
    }
    getThirdPartyId(type) {
        let result = [];
        if (this['third_party'] && this['third_party'].length) {
            for (let id of this['third_party']) {
                if (id.match(`${type}-`)) {
                    result.push(id.replace(`${type}-`, ''));
                }
            }
        }
        if (result.length > 1) {
            return result;
        }
        else if (result.length === 1) {
            return result[0];
        }
        else {
            return '';
        }
    }
    setThirdPartyId(type, id) {
        if (!(this['third_party'] instanceof Array)) {
            this['third_party'] = [];
        }
        this.remove('third_party', id); // Deprecate someday.
        this.remove('third_party', `${type}-${id}`);
        this.add('third_party', `${type}-${id}`);
    }
    clearThirdPartyId(type) {
        if (this['third_party'] && this['third_party'].length) {
            let i = 0;
            while (i > -1) {
                i = this['third_party'].findIndex(item => item.match(`${type}-`));
                this['third_party'].splice(i, 1);
            }
        }
    }
    hasThirdPartyId(type, id) {
        if (this['third_party'] && this['third_party'].length) {
            for (let id of this['third_party']) {
                if (id === `${type}-${id}`) {
                    return true;
                }
            }
        }
        return false;
    }
    async save(parent, setDoc) {
        if (!this.id) {
            this.setID();
        }
        this.date = this.date || Date.now();
        if (parent === null || parent === void 0 ? void 0 : parent._docRef) {
            this._docRef = parent._docRef.collection(this._type).doc(this.id);
        }
        if (!this._docRef) {
            throw `Cannot save ${this._type}! No document reference.`;
        }
        else {
            // Agents cannot also be followers, as agents are by default followers.
            if (this['agents'] && this['agents'].length) {
                this.remove('followers', this['agents']);
            }
            this._new = !this._exists;
            if (!(this._docRef.set instanceof Function)) {
                throw `Cannot save. If in angular context, call setDoc or updateDoc in angular code.`;
            }
            await this._docRef.set(this.toJSON());
            this._exists = true;
        }
    }
    async update(changes) {
        if (!this._docRef) {
            throw `Cannot save ${this._type}! No document reference.`;
        }
        else if (!this._exists) {
            throw `Cannot update ${this._type} record that does not yet exist.`;
        }
        else if (changes && Object.keys(changes).length) {
            Object.keys(changes).forEach(p => {
                // Gotta make sure DimensionArray properties maintain their full selves.
                if (changes[p] === undefined) {
                    delete changes[p];
                }
                else {
                    if (changes[p] instanceof index_1.DimensionArray) {
                        changes[p] = new index_1.DimensionArray(changes[p]);
                    }
                    this[p] = changes[p];
                }
                changes[p] = (0, index_1.toMinJSON)(changes, p);
            });
            if (changes && Object.keys(changes).length) {
                if (!(this._docRef.update instanceof Function)) {
                    throw `Cannot update. If in angular context, call setDoc or updateDoc in angular code.`;
                }
                this._docRef.update(changes);
            }
        }
    }
    async delete() {
        if (!this._docRef) {
            throw `Cannot delete ${this._type}! No document reference.`;
        }
        else {
            return this._docRef.delete();
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        await opts.loadAllFn(this, opts, stack, p);
    }
    async touch() {
        if (this._exists) {
            let d = { date_changed: Date.now() };
            if (this.loadAll && this['calc']) {
                await this.loadAll();
                Object.assign(d, this['calc']());
            }
            return this.update(d);
        }
    }
    fsColl(path) {
        if (this._docRef) {
            return this._docRef.collection(path);
        }
        else {
            console.error('Cannot return getColl! No _docRef present.');
        }
    }
    get fs() {
        if (this._docRef) {
            return this._docRef.firestore;
        }
        else {
            console.error('Cannot return getDoc! No _docRef present.');
        }
    }
    async fsGet() {
        if (this._docRef) {
            return this._docRef.get();
        }
        else {
            console.error('Cannot return getColl! No _docRef present.');
        }
    }
}
exports.Base = Base;
/**
 * Added to easily log sign-ins for subscription-related groups.
 */
class SignIn extends Base {
    constructor(data, olMap) {
        super('sign_ins', 12);
        this.role = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, SignIn);
    }
}
exports.SignIn = SignIn;
index_1.olm.sign_ins = (ref, map) => {
    return new SignIn(ref, map);
};
