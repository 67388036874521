"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadOnlyContact = exports.Contact = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Contact extends Base_1.Base {
    set name(n) {
        if (n) {
            let name_parts = n.split(/ /);
            switch (name_parts.length) {
                case 1:
                    this.first_name = n;
                    break;
                case 2:
                    this.first_name = name_parts[0];
                    this.last_name = name_parts[1];
                    break;
                default:
                    this.first_name = name_parts[0];
                    name_parts.splice(0, 1);
                    this.last_name = name_parts.join(' ');
                    break;
            }
        }
    }
    get name() {
        let r = `${(this.first_name) ? this.first_name : ''}${(this.last_name) ? ' ' + this.last_name : ''}`;
        ['email', 'phone'].forEach(p => {
            if (!r) {
                r = this[p];
            }
        });
        return r;
    }
    constructor(data, olMap) {
        super('contacts', 36);
        this.user_id = '';
        this.nick_name = '';
        this.first_name = '';
        this.last_name = '';
        this.company = '';
        this.phone = '';
        this.email = '';
        this.tax = {};
        this.metadata = {};
        this.counts = {};
        this.type = '';
        this.optin = null;
        this.notes = '';
        this.verified = false;
        this.third_party = []; // For storing third-party info/ids.
        this.duplicates = []; // For storing contact ids of potential duplicates.
        this.emails = []; // For storing secondary emails.
        this.locked = false; // prevent contact from being updated inside findOrAddContact.
        this.agents = [];
        this.addresses = new index_1.ChildArray();
        this.cards = new index_1.ChildArray();
        this.files = new index_1.ChildArray();
        this._m = ['name', 'first_name', 'last_name', 'nick_name', 'email', 'counts', 'company', 'phone'];
        this._s = ['name', 'first_name', 'last_name', 'email', 'third_party', 'phone', 'company', 'type', 'active'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Contact);
        if (data === null || data === void 0 ? void 0 : data.name)
            this.name = data.name;
        this.active = (this.active !== false) ? true : this.active;
    }
    toString() {
        return `Contact: ${this.name ? this.name : this.email ? this.email : this.phone ? (0, Common_1.prettifyPhone)(this.phone) : this.id}`;
    }
    getAddress(type) {
        var _a;
        let result = null;
        (_a = this.addresses) === null || _a === void 0 ? void 0 : _a.forEach((addy) => {
            if (addy.type === type && (addy.default || (!result && addy.active))) {
                result = addy;
            }
        });
        return result;
    }
    toJSON() {
        let r = super.toJSON();
        // This just makes sure we don't store the primary contact email in the duplicate emails array too
        if (this.email) {
            this.remove('emails', this.email);
        }
        return r;
    }
    toMiniJSON() {
        return {
            id: this.id,
            name: this.name || '',
            email: this.email || '',
            _type: this._type
        };
    }
    async update(changes) {
        if (changes && Object.keys(changes).length) {
            if (!changes.last_date) {
                changes.last_date = Date.now();
            }
        }
        return super.update(changes);
    }
}
exports.Contact = Contact;
class ReadOnlyContact extends Contact {
    save(parent) {
        throw 'Cannot save a ReadOnlyContact. You must be certain of what type of Contact you are using before saving';
    }
    delete(parent) {
        throw 'Cannot delete a ReadOnlyContact. You must be certain of what type of Contact you are using before removing';
    }
}
exports.ReadOnlyContact = ReadOnlyContact;
index_1.olm.contacts = (ref, map) => {
    return new Contact(ref, map);
};
