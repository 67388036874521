import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Address, OrderItem, User} from '@nxt/model-core';
import {AxekCart} from '@nxt/model-axek';

import {CartService, ICartDocument} from '@library/shared/_services/cart.service';

export interface IAxekCartDocument extends ICartDocument {
    exists?: boolean;
    checkout: AxekCart;
    receipts?: string[];
    user?: User;
    address?: Address;
    date?: number;
    status?: any;
}

@Injectable()
export class AxekCartService extends CartService {
    cartDocument$: BehaviorSubject<IAxekCartDocument> = new BehaviorSubject<IAxekCartDocument>(null);
    activeItem$: BehaviorSubject<OrderItem> = new BehaviorSubject<OrderItem>(null);
    _calculations: any[] = [];

    loadDocument(document: IAxekCartDocument): IAxekCartDocument {
        super.loadDocument(document);
        document.checkout = new AxekCart(document.checkout);
        document.checkout?.calc();
        return document;
    }

    emitDocument(c: any) {
        let document: IAxekCartDocument = c.exists() ? c.data() : {};
        document.exists = c.exists();
        this.loadDocument(document);
        this.cartDocument$.next(document);
    }

    async calculate(item: OrderItem) {
        this.calculating$.next(true);
        item.quantity = item.quantity || 1;
        this.calculating$.next(false);
    }

    async calculateShipping(latest: ICartDocument, a?: Address) {
        // We have to run calculation every time even without an address
        // because we check order totals and whether it's the weekend or evening
        // to set flags like ltlOrder$, largeOrder$, etc - something not done on EWIN.
        return super.calculateShipping(latest, a, true);
    }

    copyCart(document: IAxekCartDocument): IAxekCartDocument {
        if (document) {
            let copy: any = JSON.parse(JSON.stringify(this.toFullJSON(document)));
            return this.loadDocument(copy);
        }
        return document;
    }

    inCart(item: OrderItem, cart: IAxekCartDocument) {
        return !!this.findItem(item, cart);
    }

    findIndex(item: OrderItem, cart: IAxekCartDocument): number {
        return cart?.checkout?.items?.findIndex(i => i.sku === item.sku);
    }

}
