import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'axek-custom-loader-component',
    styleUrls: ['custom-loader.scss'],
    template: `
        <div class="overlay" [style.backgroundColor]="modalColor" *ngIf="block">
            <div class="fire">
                <div class="flames">
                    <div class="flame"></div>
                    <div class="flame"></div>
                    <div class="flame"></div>
                    <div class="flame"></div>
              </div>
              <div class="logs"></div>
          </div>
      </div>
  `
})
export class CustomLoaderComponent {
    @Input() block: boolean;
    @Input() modalColor: string = 'rgba(0,0,0,.6)';
    @Input() message: string;
}
