"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxekProduct = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class AxekProduct extends model_core_1.Product {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'products', 31);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AxekProduct);
    }
}
exports.AxekProduct = AxekProduct;
index_1.olm.products = (ref, map) => {
    return new AxekProduct(ref, map);
};
