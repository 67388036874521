export let axekLazyComponents: any = {
    'axek-contact-us': {
        t: 'Email List Sign-Up',
        d: 'Contact us form with editable headline and button text.',
        client: 'AXEK',
        loader: () => import('./contact-us/contact-us'),
        config: () => import('./contact-us/contact-us-config')
    },
    'axek-footer': {
        t: 'Site Footer',
        d: 'Dark background site footer.',
        client: 'AXEK',
        loader: () => import('./footer/axek-footer'),
        config: () => import('./footer/axek-footer-config')
    },
    'axek-nav-header': {
        t: 'Navigation Header',
        d: 'A&K navigation header',
        client: 'AXEK',
        loader: () => import('./nav-header/axek-nav-header'),
        config: () => import('./nav-header/axek-nav-header-config')
    },
    'axek-home-hero': {
        t: 'Home Hero',
        d: 'Hero image background with headline, button and icon.',
        loader: () => import('./home/hero/home-hero'),
        config: () => import('./home/hero/home-hero-config')
    },
    'axek-content-two-colum-text-left-products-right': {
        t: 'Product: Two-Column: Headline/Text on left: Product list on Right',
        d: 'Content element designed for homepage, with text content in left column and product listing on the right.',
        client: 'AXEK',
        loader: () => import('./products/text-left-products-right/content-two-colum-text-left-products-right'),
        config: () => import('./products/text-left-products-right/content-two-colum-text-left-products-right-config')
    },
    'axek-content-two-colum-products-left-text-right': {
        t: 'Product: Two-Column: Product list on left: Headline/Text',
        d: 'Content element designed for homepage, with product listing in left column and text content on the right.',
        client: 'AXEK',
        loader: () => import('./products/products-left-text-right/content-two-colum-products-left-text-right'),
        config: () => import('./products/products-left-text-right/content-two-colum-products-left-text-right-config')
    },
    'axek-myths-faq': {
       t: 'Myths Debunked FAQs',
       d: 'Myths debunked faq section, originally designed for A&K homepage.',
       client: 'AXEK',
       loader:() => import('./myths-faq/myths-faq'),
       config:() => import('./myths-faq/myths-faq-config')
    },

    'axek-product-grid-with-captions': {
        t: 'Product Grid With Captions',
        d: 'Horizontal product grid designed for type/category page. Original design included three images with captions, but the component can include up to five or six.',
        client: 'AXEK',
        loader: () => import('./products/grid-with-captions/product-grid-with-captions'),
        config: () => import('./products/grid-with-captions/product-grid-with-captions-config')
    },
    'axek-product-details': {
        t: 'Product: Details/Add to Cart',
        d: 'Main product detail section, used for product pages and includes Add to Cart button.',
        client: 'AXEK',
        loader: () => import('./products/details/product-details'),
        config: () => import('./products/details/product-details-config')
    },
    'axek-nxt-order-details': {
        t: 'Order details component used in NXT only.',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/axek/_components/axek-order-details.component')
    },
    'axek-nxt-product-details': {
        t: 'Product details editor component used in NXT only.',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/axek/_components/axek-product-details.component')
    },
   'axek-text-with-image-row': {
      t: 'Text with Image Row',
      d: 'Text headline and content with a row of images underneath',
      client: 'AXEK',
      loader: () => import('./content/text-with-image-row/text-with-image-row'),
      config: () => import('./content/text-with-image-row/text-with-image-row-config')
   },
   'axek-full-width-image-link': {
      t: 'Full Width Image Link',
      d: 'Image section with link functionality',
      client: 'AXEK',
      loader: () => import('./content/full-width-image-link/full-width-image-link'),
      config: () => import('./content/full-width-image-link/full-width-image-link-config')
   },
    'axek-hero-stripped-down': {
      t: 'Hero Stripped Down to Text',
      d: 'Hero component without any extras, only text in the middle',
      client: 'AXEK',
      loader: () => import('./home/hero-stripped-down/hero-stripped-down'),
      config: () => import('./home/hero-stripped-down/hero-stripped-down-config')
   },
};
