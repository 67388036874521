import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';

import {getAnalytics, provideAnalytics, ScreenTrackingService} from '@angular/fire/analytics';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';

import {environment} from '@env/environment';

import {ClientService} from '@library/shared/_services/client.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {PageService} from '@library/shared/_services/page.service';
import {sharedLazyComponents} from '@library/shared.lazy.components';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {ConsumerAnonAuthGuard} from '@library/consumer/_services/consumer.anon.auth.guard';
import {NotificationDialog} from '@library/shared/alert/notification.dialog';

import {AppComponent} from './app.component';
import {AxekCartService} from './_services/axek.cart.service';
import {axekLazyComponents} from './_components/axek.lazy.components';
import {CustomLoaderComponent} from './_components/custom-progress/custom-loader';
import {CustomProgressComponent} from './_components/custom-progress/custom-progress';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'u',
                    loadChildren: () => import(`./u/u.module`).then(m => m.UserAreaModule),
                    canActivate: [ConsumerAnonAuthGuard]
                },
                {
                    path: 'quote',
                    loadChildren: () => import('./u/u.module').then(m => m.UserAreaModule)
                },
                {
                    path: '**',
                    loadChildren: () => import(`@library/shared/flex/flex.page`).then(m => m.FlexPageModule),
                    data: {header: true, footer: true},
                    canActivate: [ConsumerAnonAuthGuard]
                }
            ],
            {
                initialNavigation: 'enabledNonBlocking',
                scrollPositionRestoration: 'enabled'
            }
        ),
        BrowserModule, HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:6000'
        }),
        provideFirebaseApp(() => {
            return initializeApp(environment.firebase, environment.appName);
        }),
        provideFirestore(() => {
            return getFirestore(getApp(environment.appName));
        }),
        provideAnalytics(() => {
            return getAnalytics(getApp(environment.appName));
        }),
        provideAuth( () => {
            let auth = getAuth(getApp(environment.appName));
            if (environment.type === 'local') {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
            }
            return auth;
        }),
        NotificationDialog, CustomLoaderComponent, CustomProgressComponent
    ],
    providers: [
        ClientService, LocalStorageService,
        PageService, ScreenTrackingService,
        AnalyticsService, ThemeService, AxekCartService,
        ConsumerFireService,ConsumerAccountService,
        ConsumerAnonAuthGuard,
        {provide: 'LazyComponents', useValue: [sharedLazyComponents,axekLazyComponents]},
        {provide: 'MenuService', useValue: {getMenu: () => {}}}
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
